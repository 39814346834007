
.form {
    padding-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.comments {
    width: 935px;
    padding-top: 10px;
    
}

.attachments {
    width: 935px;
    padding-top: 100px;
}

.upload-btn {
    padding-top: 20px;
    position: absolute;
    display: inline-block;
}

.submit-btn {
    padding-top: 20px;
    padding-left: 200px;
    position: absolute;
    display: inline-block;
}

.upload-msg {
    padding-top: 20px;
    text-decoration: underline;
    text-decoration-color: red;
}

.alert_msg {
    padding-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}