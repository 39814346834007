.brand-cards {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 30px;
    flex-direction: row;
    align-items: center;
    margin-left: 140px;
    margin-right: 140px;
}

.pagination {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 30px;
    color: #488FCD;
}
