.brand-cards {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.pagination {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 30px;
}